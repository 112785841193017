import { Routes } from '@angular/router';

export const routes: Routes = [
    {
		path: '',
		loadChildren: () => import('./modules/dashboard/dashboard.routes').then(r => r.DASHBOARD_ROUTES)
	},
	{
		path: 'config',
		loadChildren: () => import('./modules/config/config.routes').then(r => r.CONFIG_ROUTES)
	},
    { 
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];
